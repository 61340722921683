import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import camelCase from 'lodash.camelcase';

// Previews
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

// Components
import { ContactForm } from '../components/jobs/contact-form';

const Page = styled.div`
  & .content-container {
    margin: 60px auto 120px auto;
    max-width: 850px;

    & .heading {
      margin: 0;
    }

    & .title-container,
    & .subtitle-container {
      line-height: 170%;
    }

    b,
    strong {
      font-weight: 600;
    }

    & h2 {
      font-size: 18px;
    }

    & h1,
    & h3 {
      font-size: 22px;
      color: #000;
    }

    & .description-container {
      margin: 2em 0 0 0;

      & h3 {
        margin: 1em 0 0 0;
      }

      & ul {
        padding: 0;
        margin: 1em 0 1em 10px;

        & li {
          line-height: 150%;
          padding: 0 0 0 10px;

          ::marker {
            content: '-';
          }
        }
      }
    }

    & .apply-now {
      margin: 2em 0 0 0;

      & a {
        font-size: 22px;
        color: #000;
      }
    }
  }
`;

const Job = ({ data }) => {
  const [date, setDate] = useState();
  const [time, setTime] = useState();

  useEffect(() => {
    setDate(new Date().toLocaleDateString());
    setTime(new Date().toLocaleTimeString());
  }, []);

  const hiddenFields = data.prismicJobs.data.body.map((field, index) => {
    if (field.slice_type === `text_field`) {
      return (
        <input
          key={`form_field_${index}`}
          type="hidden"
          name={field.primary.spreadsheet_key}
          aria-required={field.primary.required}
        />
      );
    }

    if (field.slice_type === `text_area`) {
      return (
        <textarea
          key={`form_field_${index}`}
          type="hidden"
          name={field.primary.spreadsheet_key}
          rows="4"
          draggable="false"
          aria-required={field.primary.required}
        />
      );
    }

    if (field.slice_type === `dropdown_field`) {
      return (
        <select
          key={`form_field_${index}`}
          type="hidden"
          name={field.primary.spreadsheet_key}
          aria-required={field.primary.required}
        />
      );
    }
  });

  return (
    <>
      <Helmet
        title={`Jobs - ${data.prismicJob.data.job_title.text} - Treatment Studio`}
        meta={[
          {
            name: 'og:title',
            content: `Jobs - ${data.prismicJob.data.job_title.text} - Treatment Studio`,
          },
          {
            name: 'twitter:title',
            content: `Jobs - ${data.prismicJob.data.job_title.text} - Treatment Studio`,
          },
        ]}
      />

      <Page>
        <div className="content-container">
          <p className="heading">
            <strong>Job Application</strong>
          </p>
          <div
            className="title-container"
            dangerouslySetInnerHTML={{
              __html: data.prismicJob.data.job_title.html,
            }}
          />
          <div
            className="subtitle-container"
            dangerouslySetInnerHTML={{
              __html: data.prismicJob.data.job_subtitle.html,
            }}
          />

          <div className="apply-now">
            <ContactForm
              jobTitle={data.prismicJob.data.job_title.text}
              submissionMessage={data.prismicJobs.data.submission_message}
              errorSubmissionMessage={
                data.prismicJobs.data.error_submission_message
              }
              datetime={`Date: ${date}, Time: ${time}`}
            />
          </div>
        </div>
      </Page>

      <form
        name="job-enquiry"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        type="hidden"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="job-enquiry" />
        <input
          type="hidden"
          name="subject"
          data-remove-prefix
          value={`Job Enquiry – ${data.prismicJob.data.job_title.text}`}
        />
        <input
          type="hidden"
          name="Datetime"
          value={`Date: ${date}, Time: ${time}`}
        />
        {hiddenFields}
      </form>
    </>
  );
};

export default withPrismicPreview(Job);

export const query = graphql`
  query JobApplication($uid: String!) {
    prismicJob(uid: { eq: $uid }) {
      _previewable
      data {
        job_title {
          html
          text
        }
        job_subtitle {
          html
        }
        job_description {
          html
        }
      }
    }
    prismicJobs {
      data {
        email_address
        submission_message {
          html
        }
        error_submission_message {
          html
        }
        body {
          ... on PrismicJobsDataBodyTextArea {
            id
            slice_type
            primary {
              field_name
              spreadsheet_key
              required
            }
          }
          ... on PrismicJobsDataBodyTextField {
            id
            slice_type
            primary {
              field_name
              spreadsheet_key
              required
            }
          }
          ... on PrismicJobsDataBodyDropdownField {
            id
            slice_type
            primary {
              field_name
              spreadsheet_key
              required
            }
            items {
              field_option
            }
          }
        }
      }
    }
  }
`;
